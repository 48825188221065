import { useState, useEffect } from 'react';

export default function useIsScrolled({ maxScrollY = 50 }: { maxScrollY?: number } = {}) {
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > maxScrollY) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return isScrolled;
}
